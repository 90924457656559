<!-- eslint-disable -->
<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Ajouter une livraison
            </div>

            <div class="subtitle-1 font-weight-light">
              Remplir vos informations de livraison
            </div>
          </template>

          <b-form @submit.prevent="createDelivery">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="provider"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <!-- <v-text-field required
                    class="purple-input"
                    label="Nom de l'expéditeur"
                    v-model="clientname"
                  /> -->
                  <multiselect required v-model="clientnamefield" tag-placeholder="Expéditeur" placeholder="Entrer le nom de l'expédieteur" label="name" track-by="name" :options="clientSuggestions" :multiple="false" :taggable="true" @input="fillAllinfos" @tag="addNewClient"></multiselect>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="N° Téléphone de l'expéditeur"
                    class="purple-input"
                    v-model="phone"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <!-- <v-text-field required
                    class="purple-input"
                    label="Nom de receveur"
                    v-model="receivername"
                  /> -->
                  <!-- @input="getReceiverSuggestions" @change="getReceiverSuggestions" -->
                  <multiselect required v-model="receivernamefield" tag-placeholder="Receveur" placeholder="Entrer le nom de receveur" label="name" track-by="name" :options="receiverSuggestions" :multiple="false" :taggable="true" @input="fillAllinfos" @tag="addNewReceiver"></multiselect>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    required
                    label="N° Téléphone de receveur"
                    class="purple-input"
                    v-model="receiverphone"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Wilaya de depart</label>
                  <!-- <multiselect required v-model="selectedWilaya1" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="willayas" :multiple="false" :taggable="true" @input="getFees"></multiselect> -->
                  <b-form-select v-model="selectedWilaya1" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue de depart</label>
                  <!-- <multiselect required v-model="selectedWilaya1" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="willayas" :multiple="false" :taggable="true" @input="getFees"></multiselect> -->
                  <b-form-select required v-model="selectedCommunue1" @change="getFees">
                    <option v-for="communue in selectedWilaya1.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
                  </b-form-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    id="addr1"
                    required
                    label="Adresse de départ"
                    class="purple-input"
                    v-model="addr1"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Wilaya d'arivée</label>
                  <!-- <multiselect required v-model="selectedWilaya2" tag-placeholder="Lieu d'arivée" placeholder="Lieu d'arivée" label="name" track-by="name" :options="willayas" :multiple="false" :taggable="true" @input="getFees"></multiselect> -->
                  <b-form-select v-model="selectedWilaya2" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue d'arivée</label>
                  <!-- <multiselect required v-model="selectedWilaya2" tag-placeholder="Lieu d'arivée" placeholder="Lieu d'arivée" label="name" track-by="name" :options="willayas" :multiple="false" :taggable="true" @input="getFees"></multiselect> -->
                  <b-form-select required v-model="selectedCommunue2" @change="getFees">
                    <option v-for="communue in selectedWilaya2.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    id="addr2"
                    v-if="!isMultiple"
                    label="Adresse d'arrivée"
                    class="purple-input"
                    v-model="addr2"
                  />
                  <b-form-tags @input="getFees" v-else input-id="tags-basic" v-model="addr2" placeholder='Ajouter Multiple Adresses'></b-form-tags>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                <b-form-checkbox @change="isMultiple = !isMultiple;addr2=''" name="checkbox-1">Sélectionner Multiple Adress</b-form-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Marchandises"
                    class="purple-input"
                    v-model="produit"
                  />
                </v-col>
                <!-- <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-if="status == false"
                    required
                    type="number"
                    label="Prix"
                    class="purple-input"
                    v-model="prix"/>
                  <v-text-field
                    v-if="status == true"
                    required
                    disabled
                    type="number"
                    label="Prix"
                    class="purple-input"
                    v-model="prix"/>
                </v-col> -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Poids (Par Ton)"
                    class="purple-input"
                    v-model="weight"
                    type="number"
                    step="any"
                  />
                  
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <label>Type de transport</label>
                  <b-form-select v-model="selectedVehicule" @change="getFees" required>
                    <option v-for="(vehicule,index) in vehicules" :key="vehicule" :value="index">{{vehicule}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    required
                    label="Quantité"
                    class="purple-input"
                    v-model="quantity"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                <v-text-field
                  required
                  label="Porte de chargement"
                  class="purple-input"
                  v-model="charge_gate"
                />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                <v-text-field
                  required
                  label="Porte de déchargement"
                  class="purple-input"
                  v-model="discharge_gate"
                />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                <v-text-field
                  required
                  label="Documents Joints"
                  class="purple-input"
                  v-model="documents"
                />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                <label>Type de paiement</label>
                <b-form-select v-model="selectedMethod" required>
                  <option v-for="method in ['Chèque','Espèce']" :key="method" :value="method">{{method}}</option>
                </b-form-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                <label>Date de chargement</label>
                <v-row>
                <v-col
                  cols="12"
                  md="8"
                >
                <b-form-datepicker v-model="pickup.date"></b-form-datepicker>
                <!-- <v-text-field
                    required
                    type="date"
                    class="purple-input"
                    label="Date de chargement"
                    v-model="pickup.date"
                  /> -->
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                <b-form-timepicker v-model="pickup.time" locale="fr"></b-form-timepicker>
                  <!-- <v-text-field
                    required
                    type="time"
                    class="purple-input"
                    label=""
                    v-model="pickup.time"
                  /> -->
                </v-col>
                </v-row>
                </v-col>
                <!-- <v-col
                  cols="12"
                  md="4"
                >
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    @change="changeFees"
                    name="checkbox-1">
                    Livraison gratuit
                  </b-form-checkbox>
                  <p>la date : {{date}}</p>
                </v-col> -->

                <!-- <v-col
                  cols="12"
                  md="4"
                >
                  <b-form-checkbox v-if="wilaya2.dlv_type == 'both' && wilaya2.code != wilaya" v-model="checked" name="check-button" switch @change="getFees">
                    Livraison à domicile <b>(Checked: {{ checked }})</b>
                  </b-form-checkbox>
                  <b-form-checkbox v-if="wilaya2.dlv_type == 'both' && wilaya2.code == wilaya" v-model="checked" name="check-button" switch @change="getFees" disabled>
                    Livraison à domicile <b>(Checked: {{ checked }})</b>
                  </b-form-checkbox>
                  <b-form-checkbox v-if="wilaya2.dlv_type == 'stopdesk'" v-model="checked" name="check-button" switch @change="getFees" disabled>
                    Livraison à domicile <b>(Checked: {{ checked }})</b>
                  </b-form-checkbox>
                  <b-form-checkbox v-if="wilaya2.dlv_type == 'homedlv'" v-model="checked" name="check-button" switch @change="getFees" disabled>
                    Livraison à domicile <b>(Checked: {{ checked }})</b>
                  </b-form-checkbox>
                </v-col> -->
                
                <v-col v-if="checked==true"
                  cols="12"
                  md="4"
                >
                  Wilaya d'arrivé :
                  <b-form-select required @change="changeCommunues" v-model="willaya" ><option :value="wilaya" v-for="wilaya in willayas" :key="wilaya.name">{{wilaya.name}}</option> </b-form-select>

                </v-col>

                <v-col v-if="checked==true"
                  cols="12"
                  md="4"
                >
                  Communue :
                  <b-form-select required v-model="county"><option :value="communue.name" v-for="communue in communues" :key="communue.name">{{communue.name}}</option></b-form-select>
                </v-col>

                <v-col v-if="checked==true"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    class="purple-input"
                    label="Adresse"
                    v-model="address"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    class="purple-input"
                    label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                    v-model="text"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <p v-if="responseMessage != null" style="color:red;">{{responseMessage}}</p>
                  <p v-if="fais!=null && responseMessage == null" style="color:green;">Frais de livraison : {{fais}}</p>
                  <p v-if="prixtota!=null && responseMessage == null" style="color:green;">Prix total : {{prixtota}}</p>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <p  v-if="tracking_id!=null" style="color:green;">*Votre colis a été crée avec success*<br> Votre Tracking ID est  : {{tracking_id}}</p>
                </v-col>
                <v-col cols="12" md="8" class="text-left">
                  <v-btn @click="addDocs" style="background-color:#efefef;color:black;padding:5px;border-radius:5px">
                    Ajouter un nouveau document
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right"
                >
                  <v-btn v-if="fais!=null && fais!=0"
                    type='submit'
                    color="success"
                    class="mr-0"
                  >
                    Creer une livraison
                  </v-btn>
                  <v-btn v-else
                    disabled
                    type='submit'
                    color="success"
                    class="mr-0"
                  >
                    Creer une livraison
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-for="(doc,i) in docs" :key="doc.id">
                <v-col cols="12" md="6">
                  <v-text-field required v-model="doc.name" label="Nom de document" class="purple-input"/>
                </v-col>
                <v-col cols="12" md="5">
                  <!-- <input type="file" id="myfile" name="myfile"><br><br> -->
                  <input required style="margin-top:15px" type="file" id="file" ref="file" v-on:change="loadFileName(i)" accept=".pdf, .jpg, .jpeg, .png">
                </v-col>
                <v-col cols="12" md="1">
                  <v-icon @click="removeDoc(i)">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </b-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
  import axios from 'axios'
  export default {
    //
    data() {
      return{
        receiverSuggestions: [],
        clientSuggestions: [],
        receiverSuggestionsData: [],
        clientSuggestionsData: [],
        vehicules: ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN'],
        isMultiple:false,
        addr1:null,
        addr2:null,
        responseMessage: null,
        produit: null,
        provider: null,
        selectedWilaya1: {communues: {}},
        selectedCommunue1: null,
        selectedWilaya2: {communues: {}},
        selectedCommunue2: null,
        selectedVehicule: null,
        selectedMethod:null,
        wilaya: null,
        posts: [],
        wilaya2: {dlv_type:'both'},
        checked: false,
        status: false,
        clientname: null,
        receiverphone:null,
        receivername:null,
        clientnamefield:null,
        receivernamefield:null,
        prix: null,
        phone: null,
        willaya: null,
        address: null,
        pickup: {
        },
        county: null,
        tracking_id: null,
        fais: null,
        date: null,
        text: null,
        setset: null,
        fuckoff: null,
        willayas: [],
        communue: null,
        communues: [],
        prixtota: null,
        weight:null,
        quantity:null,
        charge_gate:null,
        discharge_gate:null,
        documents:null,
        docs:[],
        searchBox1:null,
        searchBox2:null,
        englishWilayas: ['Algiers [El Djazaïr]'],
        frenchWilayas: ['Alger']
      }
    },
    mounted(){
      this.pickup = {
        date:new Date(),
        time:'00:00'
      }
      this.date = new Date().toDateString()
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      this.gettingwilayas()
      this.detectingprovider()
      this.getReceiverSuggestions()
      this.getClientSuggestions()
      if(this.fuckoff=='error'){
        this.$router.push({name : "Home"})
      }
      this.googlePlaces()
    },
    methods:{
      googlePlaces() {
        let input = document.getElementById('addr1');
        // var options = {
        //   componentRestrictions: {country: 'dz'}
        // };
        this.searchBox1 = new google.maps.places.Autocomplete(input);
        this.searchBox1.setComponentRestrictions({
          country: ["dz","tn"],
        });
        this.searchBox1.addListener("place_changed", () => {
            const place = this.searchBox1.getPlace();
            if (place.plus_code != undefined) {
              this.addr1 = place.plus_code.compound_code
            } else if (!place.adr_address.includes('<')) {
              this.addr1 = place.adr_address.split(',')[0]
            } else {
              this.addr1 = place.name
            }
            
            // let w = ''
            // if (this.englishWilayas.indexOf(place.address_components[0].long_name) >= 0)
            //   w = this.frenchWilayas[this.englishWilayas.indexOf(place.address_components[0].long_name)]
            // else
            //   w = place.address_components[0].long_name
            // this.selectedWilaya1 = this.getWilayaByName(w)
            // this.selectedCommunue1 = place.address_components[1].long_name
        })
        input = document.getElementById('addr2');
        this.searchBox2 = new google.maps.places.Autocomplete(input);
        this.searchBox2.setComponentRestrictions({
          country: ["dz","tn"],
        });
        this.searchBox2.addListener("place_changed", () => {
            const place = this.searchBox2.getPlace();
            if (place.plus_code != undefined) {
              this.addr2 = place.plus_code.compound_code
            } else if (!place.adr_address.includes('<')) {
              this.addr2 = place.adr_address.split(',')[0]
            } else {
              this.addr2 = place.name
            }
            // this.selectedWilaya2 = this.getWilayaByName(place.address_components[0].long_name)
            // this.selectedCommunue2 = place.address_components[1].long_name
        })
        // this.searchBox1 = new google.maps.places.SearchBox(input,options);
        // this.searchBox1.addListener("places_changed", () => {
        //   const places = this.searchBox1.getPlaces();
        //   if (places.length == 0) {
        //     return;
        //   }
        //   console.log('here')
        // });
      },
      addNewClient(tag) {
        this.clientname = tag
        this.clientnamefield = {
          name: tag,
          id: -1
        }
        this.receivername = null
        this.phone = null
        this.receiverphone = null
        this.selectedWilaya1 = {communues: {}}
        this.selectedWilaya2 = {communues: {}}
        this.selectedCommunue1 = null
        this.selectedCommunue2 = null
        this.addr1 = null
        this.addr2 = null
      },
      addNewReceiver(tag) {
        this.receivername = tag
        this.receivernamefield = {
          name: tag,
          id: -1
        }
        this.clientname = null
        this.phone = null
        this.receiverphone = null
        this.selectedWilaya1 = {communues: {}}
        this.selectedWilaya2 = {communues: {}}
        this.selectedCommunue1 = null
        this.selectedCommunue2 = null
        this.addr1 = null
        this.addr2 = null
      },
      fillAllinfos() {
        let celement = null
        if (this.receivernamefield != null) {
          this.receivername = this.receivernamefield.name
          this.receiverSuggestionsData.forEach(element => {
            if (element.tracking_id == this.receivernamefield.id) {
              celement = element
            }
          })
          if (celement != null) {
            this.clientname = celement.client_name
            this.phone = celement.client_phone
            this.receiverphone = celement.receiver_phone
            this.selectedWilaya1 = this.getWilayaByName(celement.from_wilaya)
            this.selectedWilaya2 = this.getWilayaByName(celement.to_wilaya)
            this.selectedCommunue1 = celement.communue1
            this.selectedCommunue2 = celement.communue2
            this.addr1 = celement.address1
            this.addr2 = celement.address2
          }
        } else if (this.clientnamefield != null) {
          this.clientname = this.clientnamefield.name
          this.clientSuggestionsData.forEach(element => {
            if (element.tracking_id == this.clientnamefield.id) {
              celement = element
            }
          })
          if (celement != null) {
            this.clientname = celement.client_name
            this.phone = celement.client_phone
            this.receiverphone = celement.receiver_phone
            this.selectedWilaya1 = this.getWilayaByName(celement.from_wilaya)
            this.selectedWilaya2 = this.getWilayaByName(celement.to_wilaya)
            this.selectedCommunue1 = celement.communue1
            this.selectedCommunue2 = celement.communue2
            this.addr1 = celement.address1
            this.addr2 = celement.address2
          }
        }
        else {
            this.phone = null
            this.receiverphone = null
            this.selectedWilaya1 = {communues: {}}
            this.selectedWilaya2 = {communues: {}}
            this.selectedCommunue1 = null
            this.selectedCommunue2 = null
            this.addr1 = null
            this.addr2 = null
        }
      },
      getClientSuggestions() {
          axios.post('get-client-suggestions',{
            auth_token: localStorage.getItem('auth_token'),
            name: ''
          }).then(response => {
            this.clientSuggestions = []
            this.clientSuggestionsData = []
            if (response.data.status == 'success') {
              this.clientSuggestionsData = response.data.data
              response.data.data.forEach(element => {
                this.clientSuggestions.push({
                  name: element.client_name + ' -> ' + element.client_phone,
                  id: element.tracking_id
                })
              });
            }
          })
      },
      getReceiverSuggestions() {
          axios.post('get-receiver-suggestions',{
            auth_token: localStorage.getItem('auth_token'),
            name: ''
          }).then(response => {
            this.receiverSuggestions = []
            this.receiverSuggestionsData = []
            if (response.data.status == 'success') {
              this.receiverSuggestionsData = response.data.data
              response.data.data.forEach(element => {
                this.receiverSuggestions.push({
                  name: element.receiver_name + ' -> ' + element.receiver_phone,
                  id: element.tracking_id
                })
              });
            }
          })
      },
      loadFileName(index) {
        this.docs[index].file_name = this.$refs.file[index].files[0].name
      },
      addDocs() {
        this.docs.push({
          id:this.docs.length,
          name: '',
          file_name: '',
          content: ''
        })
        console.log(this.docs)
      },
      removeDoc(index) {
        this.docs.splice(index,1)
        console.log(this.docs)
      },
      getWilayaByName(name) {
        for (let i in this.willayas) {
          let element = this.willayas[i]
          if (element.name == name) {
            return element
          }
        }
        return {communues: {}}
      },
      gettingwilayas(){
      axios.post('get-wilayas').then(response => {

        // for (let i=0;i<response.data.data.length;i++){
          this.willayas = response.data.data
        // }
      })

    },
    changeCommunues() {
      console.log(this.willaya)
      this.communues=this.willaya.communues
    },
    createDelivery() {
      this.responseMessage = null
      let client_wilaya = null
      if (this.willaya == null) client_wilaya = null
      else client_wilaya = this.willaya.name
      if (this.isMultiple) {
        let ii = this.addr2
        this.addr2 = ''
        for (let i in ii) {
          this.addr2 += ii[i] + '****'
        }
      }
      axios.post('create-delivery',{
        auth_token: localStorage.getItem('auth_token'),
        selectedMethod:this.selectedMethod,
        produit: this.produit,
        pickup:this.pickup,
        wilaya1: this.selectedWilaya1.name,
        wilaya2: this.selectedWilaya2.name,
        communue1: this.selectedCommunue1,
        communue2: this.selectedCommunue2,
        quantity:this.quantity,
        documents:this.documents,
        charge_gate: this.charge_gate,
        discharge_gate: this.discharge_gate,
        addr1: this.addr1,
        addr2: this.addr2,
        weight:this.weight,
        text:this.text,
        clientname: this.clientname,
        receiverphone:this.receiverphone,
        receivername:this.receivername,
        phone: this.phone,
        delivery_type:this.selectedVehicule,
        prix: this.fais
      }).then(response =>{
        if (response.data.status == 'error') {
          this.responseMessage = response.data.error_message
        } else {
          this.tracking_id = response.data.data.track_id
          let formData = new FormData()
          for (let i in this.docs) {
            formData.append('file'+i,this.$refs.file[i].files[0])
          }
          formData.append('files_info', JSON.stringify(this.docs))
          formData.append('tracking_id', JSON.stringify(this.tracking_id))
          formData.append('auth_token', JSON.stringify(localStorage.getItem('auth_token')))
          axios.post('delivery-add-docs',formData,{
            headers: {
                  'Content-Type': 'multipart/form-data'
            },
          }).then(response => {
          })
          this.setset = response.data.status
          this.produit = null
          this.wilaya1 = null
          this.wilaya2 = null
          this.selectedCommunue2 = null
          this.selectedCommunue1 = null
          this.checked = false
          this.status = false
          this.clientname = null
          this.receivername = null
          this.receiverphone = null
          this.prix = null
          this.phone = null
          this.willaya = null
          this.address = null
          this.county = null
          this.text = null
        }
      })
    },
      creatcoli(){
        this.responseMessage = null
        let client_wilaya = null
        if (this.willaya == null) client_wilaya = null
        else client_wilaya = this.willaya.name
        axios.post('createcolis',{
          produit: this.produit,
          quantity: this.quantity,
          checked: this.checked,
          wilaya: this.wilaya,
          wilaya2: this.wilaya2.code,
          selectedCommunue1: this.selectedCommunue1,
          selectedCommunue2: this.selectedCommunue2,
          auth_token: localStorage.getItem('auth_token'),
          willaya: client_wilaya,
          address: this.address,
          communue: this.county,
          clientname: this.clientname,
          phone: this.phone,
          prix: this.prix,
          status: this.status,
          fees:this.fais
        }).then(response =>{
          if (response.data.status == 'error') {
            this.responseMessage = response.data.error_message
          } else {
            this.tracking_id = response.data.data.track_id
            this.setset = response.data.status
            this.prixtota = parseInt(this.prix) + parseInt(this.fais)
            this.produit = null
            this.wilaya = null
            this.wilaya2 = {dlv_type:'both'}
            this.selectedCommunue1 = null
            this.selectedCommunue2 = null
            this.checked = false
            this.status = false
            this.clientname = null
            this.prix = null
            this.phone = null
            this.willaya = null
            this.address = null
            this.county = null
            this.text = null
          }
        })

      },
      changeFees() {
        this.prix = 0
        this.getFees()
      },
      getFees() {
        if (this.selectedWilaya1!=null && this.selectedWilaya2!=null && this.selectedVehicule!=null) {
            axios.post('get-btw', {
            auth_token: localStorage.getItem('auth_token'),
            w1: this.selectedWilaya1.name,
            w2: this.selectedWilaya2.name,
            c1: this.selectedCommunue1,
            c2: this.selectedCommunue2,
            selectedVehicule: this.selectedVehicule,
          }).then(response => {
            if (response.data.status == 'success') {
              console.log(response.data.data)
              this.fais = response.data.data
              let multiple = response.data.multiple
              if (this.isMultiple) {
                this.fais = this.fais + multiple * (this.addr2.length - 1)
              }
              this.responseMessage = null
            } else if (response.data.error_message != undefined) {
              this.responseMessage = response.data.error_message
              this.fais = null
            }
          })
        } else {
          this.fais = null
          this.responseMessage = null
        }
      },
      detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider.name
          this.fuckoff = response.data.status
          for(let i=0;i<response.data.data.posts.length;i++){
            this.posts.push({code:response.data.data.posts[i].code,name:response.data.data.posts[i].name,dlv_type:response.data.data.posts[i].dlv_type})
          }
        })
      }
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>